<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('https://i.pinimg.com/originals/1c/d8/47/1cd84761e613e417f08ea18f3586a884.jpg');
        "
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title">Event Calendar </a>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <div class="row">
              <div class="col-12">
                <form action="">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Event Type</label>
                    <div class="col-sm-3">
                      <select class="form-control input-sm" name="country">
                        <option value="">All</option
                        ><option value="Johor">Product Presentation</option
                        ><option value="Kedah">Online Training</option
                        ><option value="Kelantan">Clinic</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div style="overflow-x: auto;">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Event Name</th>
                        <th scope="col">Event Type</th>
                        <th scope="col">Description</th>
                        <th scope="col">Topic</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>December 20</td>
                        <td>Week 1</td>
                        <td>3pm -5pm</td>
                        <td>Carser Training</td>
                        <td>Clinic</td>
                        <td>Features and functions.</td>
                        <td>Carser Version 12.5</td>
                        <td><b-link :to="{ name: 'Clinic' }">Join</b-link></td>
                      </tr>
                      <tr>
                        <td>December 20</td>
                        <td>Week 1</td>
                        <td>3pm -5pm</td>
                        <td>Carser Training</td>
                        <td>Online Training</td>
                        <td>Features and functions.</td>
                        <td>Carser Version 12.5</td>
                        <td>
                          <b-link :to="{ name: 'Training' }">Join</b-link>
                        </td>
                      </tr>
                      <tr>
                        <td>December 20</td>
                        <td>Week 1</td>
                        <td>3pm -5pm</td>
                        <td>Carser Training</td>
                        <td>Webinar</td>
                        <td>Features and functions.</td>
                        <td>Carser Version 12.5</td>
                        <td><b-link :to="{ name: 'Webinar' }">Join</b-link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCalendar"
};
</script>
